<template>
    <div class="top p-l-r-30">
        <img src="@/assets/img/js_ic_fh.png" @click="jumpBack" class="back" v-if="hasBack" alt="">
        <span v-else></span>
        <div class="lang-choose">
            <div class="show-content" @click="show = true">
                <img :src="host + $i18n.locale + '.png'" class="flag" alt="">
                <div class="lang-text">{{langName}}</div>
                <img src="@/assets/img/arrow.png" class="arrow" alt="">
            </div>

            <div class="list-body" v-if="show">
                <div class="one" v-for="(item, index) in LANG_TYPE" :key="index" @click="changeLanguage(item)">
                    <img :src="host + item.type + '.png'" alt="">
                    <span class="text">{{item.name}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {LANG_TYPE} from '@/config/env'

    export default {
        name: 'MenuLogin',

        props: {
            hasBack: {
                type: Boolean,
                default: true
            },
        },

        data() {
            return {
                show: false,
                langName: '',
                LANG_TYPE: LANG_TYPE,
                host: window.location.origin + '/flag/'
            };
        },

        methods: {
            // 返回上个页面
            jumpBack() {
                this.$router.go(-1);
            },
            // 切换语言
            changeLanguage(item) {
                this.$i18n.locale = item.type;
                localStorage.setItem('locale', item.type);
                this.show = false;
                this.watchName()
            },
            // 监控文本
            watchName() {
                LANG_TYPE.map((v) => {
                    if (v.type == this.$i18n.locale) {
                        this.langName = v.name
                    }
                })
            }
        },
        mounted() {
            this.watchName()
        },

        watch: {},
    };
</script>

<style>

</style>
