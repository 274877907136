// 接口请求地址
export const BASE_URL = 'https://wallet-api.imgoodx.com/';

export const KEY = 'RWFfCd94pv4vrM9O2iDG2SSAM7pRjBN4';
export const IV = 'PLfDrWXOhmC8QNCgygmknn267jG0CH2q';
export const ENCRYPT_ARR = ['password', 'password_confirmation', 'old_password', 'verification_code',  'code', 'pay_password', 'old_pay_password', 'pay_password_confirmation'];

// 无需copy env
export const Android = ''; // 安卓下载链接
export const IOS = ''; // IOS下载链接

// 无需copy env
export const COUNTRIES = [
    {
        "id": 1,
        "code": "+86",
        "length": "11",
        "abbr": "CN",
        "name": "中国",
        "en_name": "China",
        "created_at": "2019-11-14T02:29:48.000000Z",
        "updated_at": "2019-11-14T02:29:48.000000Z"
    },
    {
        "id": 2,
        "code": "+1",
        "length": "10",
        "abbr": "US",
        "name": "America",
        "en_name": "America",
        "created_at": "2019-11-14T02:29:48.000000Z",
        "updated_at": "2019-11-14T02:29:48.000000Z"
    },
    {
        "id": 3,
        "code": "+44",
        "length": "10",
        "abbr": "EN",
        "name": "England",
        "en_name": "England",
        "created_at": "2019-11-14T02:29:48.000000Z",
        "updated_at": "2019-11-14T02:29:48.000000Z"
    },
    {
        "id": 4,
        "code": "+852",
        "length": "8",
        "abbr": "HK",
        "name": "中國香港",
        "en_name": "HongKong, China",
        "created_at": "2019-11-14T02:29:48.000000Z",
        "updated_at": "2019-11-14T02:29:48.000000Z"
    },
    {
        "id": 5,
        "code": "+886",
        "length": "9",
        "abbr": "TW",
        "name": "中國台灣",
        "en_name": "TaiWan, China",
        "created_at": "2019-11-14T02:29:48.000000Z",
        "updated_at": "2019-11-14T02:29:48.000000Z"
    },
    {
        "id": 6,
        "code": "+81",
        "length": "11",
        "abbr": "JP",
        "name": "日本",
        "en_name": "Japan",
        "created_at": "2019-11-14T02:29:48.000000Z",
        "updated_at": "2019-11-14T02:29:48.000000Z"
    },
    {
        "id": 7,
        "code": "+66",
        "length": "10",
        "abbr": "TH",
        "name": "เมืองไทย",
        "en_name": "Thailand",
        "created_at": "2019-11-14T02:29:48.000000Z",
        "updated_at": "2019-11-14T02:29:48.000000Z"
    },
    {
        "id": 8,
        "code": "+65",
        "length": "8",
        "abbr": "SG",
        "name": "Singapore",
        "en_name": "Singapore",
        "created_at": "2019-11-14T02:29:48.000000Z",
        "updated_at": "2019-11-14T02:29:48.000000Z"
    },
    {
        "id": 9,
        "code": "+61",
        "length": "9",
        "abbr": "AU",
        "name": "Australia",
        "en_name": "Australia",
        "created_at": "2019-11-14T02:29:48.000000Z",
        "updated_at": "2019-11-14T02:29:48.000000Z"
    },
    {
        "id": 10,
        "code": "+46",
        "length": "9",
        "abbr": "SE",
        "name": "Sweden",
        "en_name": "Sweden",
        "created_at": "2019-11-14T02:29:48.000000Z",
        "updated_at": "2019-11-14T02:29:48.000000Z"
    },
    {
        "id": 11,
        "code": "+966",
        "length": "9",
        "abbr": "SA",
        "name": "مملكة عربية سعودية",
        "en_name": "Saudi Arabia",
        "created_at": "2019-11-14T02:29:48.000000Z",
        "updated_at": "2019-11-14T02:29:48.000000Z"
    },
    {
        "id": 12,
        "code": "+7",
        "length": "11",
        "abbr": "RU",
        "name": "Россия",
        "en_name": "Russia",
        "created_at": "2019-11-14T02:29:48.000000Z",
        "updated_at": "2019-11-14T02:29:48.000000Z"
    },
    {
        "id": 13,
        "code": "+351",
        "length": "9",
        "abbr": "PT",
        "name": "Portuguesa",
        "en_name": "Portugal",
        "created_at": "2019-11-14T02:29:48.000000Z",
        "updated_at": "2019-11-14T02:29:48.000000Z"
    },
    {
        "id": 14,
        "code": "+63",
        "length": "10",
        "abbr": "PH",
        "name": "Philippines",
        "en_name": "Philippines",
        "created_at": "2019-11-14T02:29:48.000000Z",
        "updated_at": "2019-11-14T02:29:48.000000Z"
    },
    {
        "id": 15,
        "code": "+48",
        "length": "8",
        "abbr": "PL",
        "name": "Polska",
        "en_name": "Poland",
        "created_at": "2019-11-14T02:29:48.000000Z",
        "updated_at": "2019-11-14T02:29:48.000000Z"
    },
    {
        "id": 16,
        "code": "+64",
        "length": "10",
        "abbr": "NZ",
        "name": "New Zealand",
        "en_name": "new Zealand",
        "created_at": "2019-11-14T02:29:48.000000Z",
        "updated_at": "2019-11-14T02:29:48.000000Z"
    },
    {
        "id": 17,
        "code": "+31",
        "length": "10",
        "abbr": "NL",
        "name": "Nederland",
        "en_name": "Netherlands",
        "created_at": "2019-11-14T02:29:48.000000Z",
        "updated_at": "2019-11-14T02:29:48.000000Z"
    },
    {
        "id": 18,
        "code": "+853",
        "length": "7",
        "abbr": "MAC",
        "name": "中國澳門",
        "en_name": "Macao, China",
        "created_at": "2019-11-14T02:29:48.000000Z",
        "updated_at": "2019-11-14T02:29:48.000000Z"
    },
    {
        "id": 19,
        "code": "+60",
        "length": "9,10",
        "abbr": "MYS",
        "name": "Malaysia",
        "en_name": "Malaysia",
        "created_at": "2019-11-14T02:29:48.000000Z",
        "updated_at": "2019-11-14T02:29:48.000000Z"
    },
    {
        "id": 20,
        "code": "+62",
        "length": "10",
        "abbr": "IDN",
        "name": "Indonesia",
        "en_name": "Indonesia",
        "created_at": "2019-11-14T02:29:48.000000Z",
        "updated_at": "2019-11-14T02:29:48.000000Z"
    },
    {
        "id": 21,
        "code": "+91",
        "length": "11",
        "abbr": "IND",
        "name": "भारत गणराज्य",
        "en_name": "India",
        "created_at": "2019-11-14T02:29:48.000000Z",
        "updated_at": "2019-11-14T02:29:48.000000Z"
    },
    {
        "id": 22,
        "code": "+1",
        "length": "10",
        "abbr": "CAN",
        "name": "Canada",
        "en_name": "Canada",
        "created_at": "2019-11-14T02:29:48.000000Z",
        "updated_at": "2019-11-14T02:29:48.000000Z"
    },
    {
        "id": 23,
        "code": "+82",
        "length": "11",
        "abbr": "KOR",
        "name": "대한민국",
        "en_name": "Korea ",
        "created_at": "2019-11-14T02:29:48.000000Z",
        "updated_at": "2019-11-14T02:29:48.000000Z"
    },
    {
        "id": 24,
        "code": "+84",
        "length": "10,11",
        "abbr": "VNM",
        "name": "Việt nam",
        "en_name": "Vietnam",
        "created_at": "2019-11-14T02:29:48.000000Z",
        "updated_at": "2019-11-14T02:29:48.000000Z"
    },
    {
        "id": 25,
        "code": "+33",
        "length": "10",
        "abbr": "FRA",
        "name": "République française",
        "en_name": "French",
        "created_at": "2019-11-14T02:29:48.000000Z",
        "updated_at": "2019-11-14T02:29:48.000000Z"
    },
    {
        "id": 26,
        "code": "+49",
        "length": "10",
        "abbr": "DEU",
        "name": "Deutschland",
        "en_name": "Germany",
        "created_at": "2019-11-14T02:29:48.000000Z",
        "updated_at": "2019-11-14T02:29:48.000000Z"
    }
];

// 无需copy env
export const LANG_TYPE = [
    {
        id: '0',
        name: '简体中文',
        type: 'zh_CN',
    },
    {
        id: '1',
        name: 'English',
        type: 'en',
    },
    {
        id: '2',
        name: '繁體中文',
        type: 'zh_TW',
    },
];
