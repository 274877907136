<template>
    <div class="login">
        <div class="login-bg">
            <!--lang-->
            <MenuLogin :hasBack="false"/>
            <div class="login-title over-line-1">{{$i18n.t('language.registerMsg')}}</div>

<!--            <div class="login-menu">-->
<!--                <div class="menu-one over-line-1" :class="type == 2 ? 'on' : ''" @click="selectType(2)">{{$i18n.t('language.phoneRegistration')}}</div>-->
<!--                <div class="line"></div>-->
<!--                <div class="menu-one over-line-1" :class="type == 1 ? 'on' : ''" @click="selectType(1)">{{$i18n.t('language.emailRegistration')}}</div>-->
<!--            </div>-->

            <div class="input-group">
                <i-input v-model="form.phone" v-if="type == 2" autocomplete="new-password">
                    <div slot="prepend" @click="PhoneCountry">
                        <img src="../../assets/input/input_phone@2x.png" alt="">
                        <span class="text">{{form.country_code}}</span>
                        <img class="img" src="../../assets/input/input_down@2x.png" alt="">
                    </div>
                </i-input>
                <i-input v-model="form.email" v-else element-id="email" autocomplete="new-password">
                    <div slot="prepend" @click="focusFn('email')">
                        <img src="../../assets/input/input_email@2x.png" alt="">
                        <span class="text min">{{$i18n.t('language.email')}}</span>
                    </div>
                </i-input>

                <i-input v-model="form.verification_code" v-if="type == 2" element-id="verification_code_phone" autocomplete="new-password">
                    <div slot="prepend" @click="focusFn('verification_code_phone')">
                        <img src="../../assets/input/input_code@2x.png" alt="">
                        <span class="text min">{{$i18n.t('language.verificationCode')}}</span>
                    </div>
                    <div slot="append" @click="sendPhoneCode">{{sendText}}</div>
                </i-input>
                <i-input v-model="form.verification_code" v-else element-id="verification_code_email" autocomplete="new-password">
                    <div slot="prepend" @click="focusFn('verification_code_email')">
                        <img src="../../assets/input/input_code@2x.png" alt="">
                        <span class="text min">{{$i18n.t('language.verificationCode')}}</span>
                    </div>
                    <div slot="append" @click="sendEmailCode">{{sendText}}</div>
                </i-input>

                <i-input v-model="form.user_name" element-id="verification_username">
                    <div slot="prepend" @click="focusFn('verification_username')">
                        <img src="../../assets/input/input_username@2x.png" alt="">
                        <span class="text min">{{$i18n.t('language.username')}}</span>
                    </div>
                </i-input>

                <i-input v-model="form.password" :type="!passwordShow ? 'password' : 'text'" autocomplete="new-password" element-id="password">
                    <div slot="prepend" @click="focusFn('password')">
                        <img src="../../assets/input/input_password@2x.png" alt="">
                        <span class="text min">{{$i18n.t('language.loginPassword')}}</span>
                    </div>
                    <div slot="append" @click="passwordShow = !passwordShow">
                        <img src="../../assets/input/input_eys@2x.png" v-if="!passwordShow" alt="">
                        <img src="../../assets/input/input_eys_on@2x.png" class="eys-on" v-else alt="">
                    </div>
                </i-input>

                <i-input v-model="form.password_confirmation" :type="!password_confirmationShow ? 'password' : 'text'" autocomplete="new-password" element-id="password_confirmationShow">
                    <div slot="prepend" @click="focusFn('password_confirmationShow')">
                        <img src="../../assets/input/input_password@2x.png" alt="">
                        <span class="text min">{{$i18n.t('language.confirmPassword')}}</span>
                    </div>
                    <div slot="append" @click="password_confirmationShow = !password_confirmationShow">
                        <img src="../../assets/input/input_eys@2x.png" v-if="!password_confirmationShow" alt="">
                        <img src="../../assets/input/input_eys_on@2x.png" class="eys-on" v-else alt="">
                    </div>
                </i-input>

                <i-input v-model="form.invitation_code" element-id="invitation_code" :disabled="!!$route.query.code">
                    <div slot="prepend" @click="focusFn('invitation_code')">
                        <img src="../../assets/input/input_invite@2x.png" alt="">
                        <span class="text min">{{$i18n.t('language.invitationCode')}}</span>
                    </div>
                </i-input>
                <div class="bottom-msg">
                    <img src="../../assets/input/input_select_on@2x.png" alt="">
                    <span>{{$i18n.t('language.registerMsg2')}} </span>
                    <span class="on" @click="$router.push('/userAgreement/zh_CN')">{{$i18n.t('language.registerMsg3')}}</span>
                </div>

                <div class="button-custom" @click="register">{{ $t('language.register') }}</div>
                <div class="button-custom-download" style="" @click="$router.push('/download')">{{ $t('language.registerMsg6') }}</div>
            </div>
        </div>
        <!--选择国家和地区-->
        <Modal
                fullscreen
                :closable="false"
                v-model="modalType.show"
                :title="$t('language.selectCountryRegion')">
            <p>
                <RadioGroup v-model="form.country_id" vertical>
                    <Radio v-for="(item, key) in country"  :label="item.id" :key="key">
                        <span>{{item.code}} {{item.name}}</span>
                    </Radio>
                </RadioGroup>
            </p>
            <div slot="footer">
                <Button type="default" size="large" @click="chooseCountry">{{$i18n.t('language.sure')}}</Button>
            </div>
        </Modal>

        <div class="modal" v-if="modalType.show2">
            <div class="modal-bg">
                <img src="../../assets/img/modal_info.png" alt="">
                <div class="modal-text">{{$i18n.t('language.tip')}}</div>
                <div class="modal-content">
                  <span class="top over-line-1">{{$i18n.t('language.registerMsg4')}}</span>
                  <span class="bottom over-line-1">{{$i18n.t('language.registerMsg5')}}</span>
                  <span class="bottom over-line-1">{{$i18n.t('language.registerMsg7')}}{{form.email}}</span>
                  <div class="button" @click="$router.push('/download')">{{$i18n.t('language.registerMsg6')}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import MenuLogin from '@/components/customize/MenuLogin.vue';
    import User from '@/api/user';
    import {COUNTRIES} from "../../config/env.example";

    export default {
        name: 'Register',

        props: {},
        data() {
            return {
                sendText: this.$i18n.t('language.obtain'),
                canSend: true,
                sendTextEmail: this.$i18n.t('language.obtain'),
                canSendEmail: true,
                type: 1,
                loading: false,
                form: {
                    type: 1,
                    country_id: 1,
                    country_code: '+ 86',
                    phone: '',
                    email: '',
                    user_name: '',
                    verification_code: '',
                    password: '',
                    password_confirmation: '',
                    invitation_code: this.$route.query.code || ''
                },

                country: COUNTRIES,
                modalType: {
                    show: false,
                    show2: false
                },

                passwordShow: false,
                password_confirmationShow: false
            }
        },
        components: {
            MenuLogin
        },
        mounted() {

        },
        watch: {},
        methods: {
            // 发送短信
            sendPhoneCode() {
                if(!this.form.phone) {
                    return;
                }
                this.form.type = 1;
                if (!this.canSend) return;
                this.canSend = false;
                this.sendText = 60;
                this.timer = setInterval(() => {
                    if (this.sendText === 1) {
                        this.canSend = true;
                        this.sendText = this.$i18n.t('language.obtain');
                        this.timer && clearInterval(this.timer);
                    } else {
                        this.sendText = this.sendText - 1
                    }
                }, 1000);

                User.sendPhoneCode(this, () => {});
            },
            // 发送邮件
            sendEmailCode() {
                if(!this.form.email) {
                    return;
                }
                this.form.type = 1;
                if (!this.canSendEmail) return;
                this.canSendEmail = false;
                this.sendTextEmail = 60;
                this.timerEmail = setInterval(() => {
                    if (this.sendTextEmail == 1) {
                        this.canSendEmail = true;
                        this.sendTextEmail = this.$i18n.t('language.obtain');
                        this.timerEmail && clearInterval(this.timerEmail);
                    } else {
                        this.sendTextEmail = this.sendTextEmail - 1
                    }
                }, 1000);

                User.sendEmailCode(this, () => {})
            },
            // 注册
            register() {
                this.form.type = this.type;
                User.register(this, () => {
                    this.modalType.show2 = true
                });
            },
            // 路由
            jump(route) {
                this.$router.push(route)
            },
            // 更换类型
            selectType(type) {
                this.type = type
            },
            // 选择国家和地区
            PhoneCountry() {
                // this.$Message.warning(this.$i18n.t('language.validateMsg17'));
                User.countries(this, (data) => {
                    if (data.code) {
                        this.$Message.error({background: true, content: data});
                        return false;
                    }
                    this.country = data.data;
                    this.modalType.show = true;
                })
            },
            chooseCountry(){
                this.country.map((v) => {
                    if(v.id == this.form.country_id) {
                        this.form.country_code = v.code;
                        this.modalType.show = false
                    }
                })
            },
            focusFn(id){
                document.getElementById(id).focus()
            }
        }
    };
</script>

<style scoped lang="less">
    .modal {
        background: rgba(0, 0, 0, .3);
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 99;
        display: flex;
        align-items: center;
        justify-content: center;

        .modal-bg {
          background: url("../../assets/img/modal_bg.png") center top no-repeat;
          width: 70.4rem;
          background-color: #137C51;
          background-size: cover;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          border-radius: 3rem;

          img {
              height: 18rem;
              width: 18rem;
              margin: -9rem auto 0;
          }

          .modal-text {
            font-size: 4rem;
            text-align: center;
            margin: 2rem 0 3rem;
            color: #ffffff;
          }

          .modal-content {
            background-color: #ffffff;
            padding: 3rem;
            margin-bottom: 5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 64rem;
            border-radius: 2rem;

            span {
              font-size: 3rem;
              color: #333;
              margin-bottom: 5px;
            }
          }

          .button {
            height: 8rem;
            font-size: 3.2rem;
            color: #ffffff;
            width: 26rem;
            border-radius: 1.5rem;
            background: #137C51;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 4rem;
            margin-top: 6rem;
          }
        }
    }
</style>
